@font-face {
  font-family: "Marcellus SC";
  src: url("../fonts/MarcellusSC-Regular.ttf") format("truetype");
}
* {
  margin: 0;
  padding: 0;
}

html {
  overflow: hidden;
  overflow-y: scroll;
}

body {
  overflow: hidden;
  background-color: #0D1823;
}

a {
  text-decoration: none;
}

.breakLine {
  position: relative;
  width: 85vw;
  height: 5px;
  background-color: #ffffff;
  left: 50%;
  transform: translate(-50%);
}

#about {
  min-height: 70vh;
  color: white;
  font-family: "Marcellus SC";
}
#about h2 {
  position: relative;
  text-align: center;
  margin-top: 10vh;
}

.timelineItem {
  margin-top: 5vh;
  margin-bottom: 5vh;
}
.timelineItem h3 {
  text-align: center;
  font-size: 2em;
}
.timelineItem p {
  position: relative;
  text-align: left;
  width: 70vw;
  transform: translate(-50%);
  left: 50vw;
  font-size: 1.5em;
}

#accomplishments {
  color: white;
  font-family: "Marcellus SC";
}
#accomplishments h2 {
  margin-top: 3vh;
  text-align: center;
  margin-bottom: 5vh;
  font-size: 2em;
}
#accomplishments ul {
  font-size: 1.5em;
  position: relative;
  left: 50vw;
  transform: translate(-50%);
  width: 50vw;
}

#projects {
  font-family: "Marcellus SC";
}
#projects h2 {
  margin-top: 3vh;
  text-align: center;
  color: white;
  font-size: 2em;
}
#projects .projectCard {
  position: relative;
  padding: 20px;
  background-color: #D9D9D9;
  width: 50vw;
  height: auto;
  left: 50vw;
  transform: translate(-50%);
  margin-top: 5vh;
  margin-bottom: 5vh;
}
#projects .projectCard h3 {
  font-size: 2em;
}

#clouds {
  overflow: hidden;
  padding: 100px 0;
  background: #0D1823;
  width: 100vw;
  height: 100vh;
}

.cloud {
  width: 200px;
  height: 60px;
  background: #fff;
  border-radius: 200px;
  -moz-border-radius: 200px;
  -webkit-border-radius: 200px;
  position: relative;
}

.cloud:before, .cloud:after {
  content: "";
  position: absolute;
  background: #fff;
  width: 100px;
  height: 80px;
  position: absolute;
  top: -15px;
  left: 10px;
  border-radius: 100px;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  transform: rotate(30deg);
  -moz-transform: rotate(30deg);
}

.cloud:after {
  width: 120px;
  height: 120px;
  top: -55px;
  left: auto;
  right: 15px;
}

.x1 {
  animation: moveclouds 25s linear infinite;
  -webkit-animation: moveclouds 25s linear infinite;
  -moz-animation: moveclouds 25s linear infinite;
  -o-animation: moveclouds 25s linear infinite;
}

.x2 {
  left: 200px;
  transform: scale(0.6);
  opacity: 0.6;
  animation: moveclouds 30s linear infinite;
  -webkit-animation: moveclouds 30s linear infinite;
  -moz-animation: moveclouds 30s linear infinite;
  -o-animation: moveclouds 30s linear infinite;
}

.x3 {
  left: -250px;
  top: -200px;
  transform: scale(0.8);
  opacity: 0.8;
  animation: moveclouds 35s linear infinite;
  -webkit-animation: moveclouds 35s linear infinite;
  -moz-animation: moveclouds 35s linear infinite;
  -o-animation: moveclouds 35s linear infinite;
}

.x4 {
  left: 470px;
  top: -250px;
  transform: scale(0.75);
  opacity: 0.75;
  animation: moveclouds 25s linear infinite;
  -webkit-animation: moveclouds 25s linear infinite;
  -moz-animation: moveclouds 25s linear infinite;
  -o-animation: moveclouds 25s linear infinite;
}

.x5 {
  left: -150px;
  top: -150px;
  transform: scale(0.8);
  opacity: 0.8;
  animation: moveclouds 20s linear infinite;
  -webkit-animation: moveclouds 20s linear infinite;
  -moz-animation: moveclouds 20s linear infinite;
  -o-animation: moveclouds 20s linear infinite;
}

@keyframes moveclouds {
  0% {
    margin-left: 125vw;
  }
  100% {
    margin-left: -125vw;
  }
}
.et-hero-tabs, .et-slide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
  text-align: center;
  padding: 0 2em;
}
.et-hero-tabs #intro, .et-slide #intro {
  position: absolute;
  top: 50vh;
  left: 50vw;
  color: #ffffff;
  text-align: center;
  z-index: 1;
  transform: translate(-50%, -50%);
  width: 100vw;
  font-family: "Marcellus SC"; /* Safari, Chrome and Opera > 12.1 */ /* Firefox < 16 */ /* Internet Explorer */ /* Opera < 12.1 */
  animation: fadein 2s;
}
.et-hero-tabs #intro h1, .et-slide #intro h1 {
  font-size: 5em;
}
.et-hero-tabs h3, .et-slide h3 {
  font-size: 2rem;
  color: #434656;
}

.et-hero-tabs-container {
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 70px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  background: #121212;
  z-index: 10;
}
.et-hero-tabs-container--top {
  position: fixed;
  top: 0;
}

.et-hero-tab {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  color: #fff;
  letter-spacing: 0.1rem;
  transition: all 0.5s ease;
  font-size: 0.8rem;
}
.et-hero-tab:hover {
  color: #000;
  background: #B5A882;
  transition: all 0.1s ease;
}

.et-hero-tab-slider {
  position: absolute;
  bottom: 0;
  width: 0;
  height: 6px;
  background: #B5A882;
  transition: left 0.3s ease;
}

@media (min-width: 800px) {
  .et-hero-tabs h1,
  .et-slide h1 {
    font-size: 3rem;
  }
  .et-hero-tabs h3,
  .et-slide h3 {
    font-size: 1rem;
  }
  .et-hero-tab {
    font-size: 1rem;
  }
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-links {
  position: relative;
  top: 5vw;
  display: flex;
  width: 50vw;
  justify-content: center;
  align-items: center;
  transform: translate(-50%);
  left: 50vw;
}

.resumeButton {
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  background: #B5A882;
  border-radius: 10px;
  margin: 5px;
  color: #333;
  height: 50px;
  width: 100px;
  cursor: pointer;
  text-align: center;
}

.social-btn {
  cursor: pointer;
  height: 50px;
  width: 50px;
  font-family: "Titillium Web", sans-serif;
  color: #333;
  border-radius: 10px;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  background: #B5A882;
  margin: 5px;
  transition: 1s;
}

.social-btn span {
  width: 0px;
  overflow: hidden;
  transition: 1s;
  text-align: center;
}

.social-btn:hover {
  width: 180px;
  border-radius: 5px;
}

.social-btn:hover span {
  padding: 2px;
  width: -moz-max-content;
  width: max-content;
}

#linkedin svg {
  fill: #0e76a8;
}

#github {
  fill: #333;
}

#buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 10vh;
  width: 50vw;
}

button {
  position: relative;
  margin-left: 1vw;
  margin-right: 1vw;
  margin-top: 3vh;
  background: #444;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  border: none;
  letter-spacing: 0.1rem;
  font-size: 1rem;
  padding: 1rem 2rem;
  transition: 0.2s;
}

button:hover {
  letter-spacing: 0.2rem;
  padding: 1.1rem 2.1rem;
  background: #B5A882;
  color: #B5A882;
  animation: box 3s infinite;
}

button::before {
  content: "";
  position: absolute;
  inset: 2px;
  background: #272822;
}

button span {
  position: relative;
  z-index: 1;
}

button i {
  position: absolute;
  inset: 0;
  display: block;
}

button i::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 2px;
  left: 80%;
  top: -2px;
  border: 2px solid #B5A882;
  background: #272822;
  transition: 0.2s;
}

button:hover i::before {
  width: 15px;
  left: 20%;
  animation: move 3s infinite;
}

button i::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 2px;
  left: 20%;
  bottom: -2px;
  border: 2px solid #B5A882;
  background: #272822;
  transition: 0.2s;
}

button:hover i::after {
  width: 15px;
  left: 80%;
  animation: move 3s infinite;
}

@keyframes move {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes box {
  0% {
    box-shadow: #27272c;
  }
  50% {
    box-shadow: 0 0 25px #B5A882;
  }
  100% {
    box-shadow: #27272c;
  }
}/*# sourceMappingURL=style.css.map */